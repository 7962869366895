import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, Field, FormikProps, getIn, FormikHelpers } from 'formik';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { User, Mail, Lock } from '@lifeomic/chromicons';
import { FormikTextField } from '@lifeomic/phc-web-toolkit/dist/components/ChromaFormik';
import useValidators, {
  trimWhitespaceFromForm,
} from '../../../hooks/useValidatiors';
import { Button } from '@lifeomic/chroma-react/components/Button';
import PasswordCriteria from '../../common/PasswordCriteria';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../../redux/modules/Login';
import { useSignupEndpoint } from '../../../hooks/api';
import { Collapse, Fade } from '@mui/material';
import { push } from 'connected-react-router';
import urls from '../../../util/urls';
import { getTestProps } from '../../../util/testProps';
import { SignupFormProps } from '.';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';
import { ologyColors } from '../../../styles';
import clsx from 'clsx';
import { FormBox } from '@lifeomic/chroma-react/components/FormBox';
import { FeatureToggles, fetchFeatures } from '../../../util/featureToggle';
import {
  Radio,
  RadioGroupMinimal,
} from '@lifeomic/chroma-react/components/Radio';
import { generatePassword } from '../../../util/passwordlessAuth';
import { Text } from '@lifeomic/chroma-react/components/Text';

export const testIds = {
  username: 'signupForm-username',
  email: 'signupForm-email',
  reenterEmail: 'signupForm-reenterEmail',
  password: 'signupForm-password',
  reenterPassword: 'signupForm-reenterPassword',
  submitButton: 'signupForm-submitButton',
  passwordToggle: 'signupForm-passwordToggle',
  passwordlessToggle: 'signupForm-passwordlessToggle',
};

const getMessages = (isLifeologyLogin: boolean) => ({
  username: {
    id: 'signupForm.userName',
    defaultMessage: 'Username',
  },
  email: {
    id: 'signupForm.email',
    defaultMessage: 'Email',
  },
  reenterEmail: {
    id: 'signupForm.reenterEmail',
    defaultMessage: 'Confirm Email',
  },
  password: {
    id: 'signupForm.password',
    defaultMessage: 'Password',
  },
  reenterPassword: {
    id: 'signupForm.reenterPassword',
    defaultMessage: 'Confirm Password',
  },
  signUp: {
    id: 'signupForm.signUp',
    defaultMessage: isLifeologyLogin ? 'SIGN UP' : 'Sign Up',
  },
  misMatchedEmails: {
    id: 'signupForm.misMatchedEmails',
    defaultMessage: 'Emails must match',
  },
  misMatchedPassword: {
    id: 'signupForm.misMatchedPassword',
    defaultMessage: 'Passwords must match',
  },
  signUpWithoutPasswordRadio: {
    id: 'signupForm.signUpWithoutPasswordRadio',
    defaultMessage: 'Passwordless',
  },
  signUpWithPasswordRadio: {
    id: 'signupForm.signUpWithPasswordRadio',
    defaultMessage: 'Password',
  },
  signUpWithPassword: {
    id: 'signupForm.signUpWithPassword',
    defaultMessage: 'We’ll email you a link for a passwordless sign in.',
  },
});

export type FormValues = {
  username: string;
  email: string;
  reenteredEmail: string;
  password: string;
  reenteredPassword: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  signupButton: {
    marginTop: theme.spacing(4),
    height: theme.pxToRem(40),
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
  lifeologyButton: {
    borderRadius: theme.pxToRem(20),
    backgroundColor: 'none',
    backgroundImage: ologyColors.btnGradient,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundImage: ologyColors.btnGradientHover,
    },
    '&:disabled': {
      backgroundImage: ologyColors.btnGradient,
      opacity: 0.65,
    },
  },
  criteria: {
    marginTop: theme.spacing(1),
  },
  hidden: {
    '&&': {
      margin: 0,
    },
  },
  confirmPassword: {
    marginTop: theme.spacing(1),
  },
  collapseContainer: {
    '&.MuiCollapse-hidden': {
      marginBottom: 0,
    },
  },
  radioBtn: {
    flex: 1,
  },
  lifeologyRadioMinBtn: {
    '& input:checked + div label > p': {
      color: theme.palette.text.primary,
    },
  },
  signUpWithPassword: {
    color: theme.palette.text.hint,
    fontSize: theme.typography.caption.fontSize,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  box: {
    strokeOpacity: 0.5,
  },
}));

export type SignupFormClasses = GetClasses<typeof useStyles>;

const initialValues: FormValues = {
  username: '',
  email: '',
  reenteredEmail: '',
  password: '',
  reenteredPassword: '',
};

const SharedSignupForm = (props: SignupFormProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const validators = useValidators();
  const dispatch = useDispatch();
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);
  const hidePasswordless = useSelector(loginSelectors.selectHidePasswordless);
  const [signupRequestState, signupUser] = useSignupEndpoint();
  const loginApp = useLoginApp();
  const isLifeologyLogin = loginApp === LoginApps.lifeology;
  const [features, setFeatures] = React.useState<FeatureToggles>();
  const [isPasswordSignUp, setIsPasswordSignUp] = useState<boolean>(false);

  React.useEffect(() => {
    async function doFetchFeatures() {
      const features = await fetchFeatures();
      setFeatures(features);
      if (!features?.passwordlessAuth || hidePasswordless) {
        setIsPasswordSignUp(true);
      }
    }

    doFetchFeatures();
  }, []);

  const onSubmit = React.useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      const cleanFormValues = trimWhitespaceFromForm(formValues);
      const { username } = cleanFormValues;
      const { email } = cleanFormValues;

      const randomPassword = {
        password: generatePassword(),
      };
      const { password } = isPasswordSignUp ? formValues : randomPassword;

      const persistSignupParamsAction = loginActions.persistSignupParams(
        username,
        email,
        password
      );

      signupUser(username, password, email, originalUrl, () => {
        dispatch(persistSignupParamsAction);
        dispatch(
          push(urls.app.roots.verification({ originalUrl, email, username }))
        );
      });
      actions.setSubmitting(false);
    },
    [dispatch, originalUrl, signupUser]
  );

  const handlePasswordCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPasswordSignUp(e.target.value === 'password');
  };

  const emailValidator = validators.signupEmail;
  const passwordValidator = validators.password;
  const usernameValidator = validators.signupUsername;
  const messages = getMessages(isLifeologyLogin);

  const validateForm = React.useCallback((formValues: FormValues) => {
    const errors: any = {};
    if (
      !emailValidator(formValues.email) &&
      !!formValues.reenteredEmail &&
      formValues.email !== formValues.reenteredEmail
    ) {
      const mismatchEmailError = formatMessage(messages.misMatchedEmails);
      errors.email = mismatchEmailError;
      errors.reenteredEmail = mismatchEmailError;
    }

    if (
      isPasswordSignUp &&
      !passwordValidator(formValues.password) &&
      !!formValues.reenteredPassword &&
      formValues.password !== formValues.reenteredPassword
    ) {
      const mismatchedPasswordError = formatMessage(
        messages.misMatchedPassword
      );
      errors.password = mismatchedPasswordError;
      errors.reenteredPassword = mismatchedPasswordError;
    }

    return errors;
  }, []);

  const signUpButton = (isSubmitting: boolean) => (
    <Button
      {...getTestProps(testIds.submitButton)}
      className={clsx(
        classes.signupButton,
        isLifeologyLogin && classes.lifeologyButton
      )}
      disabled={signupRequestState.isLoading || isSubmitting}
      type="submit"
      variant="contained"
    >
      {formatMessage(messages.signUp)}
    </Button>
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateForm}
    >
      {(formikProps: FormikProps<FormValues>) => {
        const showReenterEmail = !emailValidator(formikProps.values.email);
        const showReenterPassword = !passwordValidator(
          formikProps.values.password
        );

        return (
          <Form>
            <FormBox paddingTop={4}>
              <Field
                {...getTestProps(testIds.username)}
                aria-label="Username"
                fullWidth
                name="username"
                autoComplete="username"
                component={FormikTextField}
                placeholder={formatMessage(messages.username)}
                validate={usernameValidator}
                startAdornment={<User aria-hidden />}
              />
              <Field
                {...getTestProps(testIds.email)}
                aria-label="Email"
                fullWidth
                name="email"
                autoComplete="email"
                component={FormikTextField}
                placeholder={formatMessage(messages.email)}
                validate={emailValidator}
                startAdornment={<Mail aria-hidden />}
              />
              <Collapse
                in={showReenterEmail}
                className={classes.collapseContainer}
              >
                <Fade in={showReenterEmail}>
                  <div>
                    <Field
                      {...getTestProps(testIds.reenterEmail)}
                      aria-label="Re-entered Email"
                      fullWidth
                      name="reenteredEmail"
                      autoComplete="email"
                      component={FormikTextField}
                      placeholder={formatMessage(messages.reenterEmail)}
                      validate={validators.required}
                      startAdornment={<Mail aria-hidden />}
                    />
                  </div>
                </Fade>
              </Collapse>
              {!!features?.passwordlessAuth && !hidePasswordless && (
                <RadioGroupMinimal
                  aria-label="Password Selector"
                  name="pageSelector"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePasswordCheck(e)
                  }
                  value={'passwordless'}
                  fullWidth
                >
                  <Radio
                    className={clsx(
                      classes.radioBtn,
                      isLifeologyLogin && classes.lifeologyRadioMinBtn
                    )}
                    {...getTestProps(testIds.passwordlessToggle)}
                    value="passwordless"
                    label={formatMessage(messages.signUpWithoutPasswordRadio)}
                  />
                  <Radio
                    className={clsx(
                      classes.radioBtn,
                      isLifeologyLogin && classes.lifeologyRadioMinBtn
                    )}
                    {...getTestProps(testIds.passwordToggle)}
                    value="password"
                    label={formatMessage(messages.signUpWithPasswordRadio)}
                  />
                </RadioGroupMinimal>
              )}
              <Collapse
                in={isPasswordSignUp}
                className={classes.collapseContainer}
              >
                <Fade in={isPasswordSignUp}>
                  <div>
                    <Field
                      {...getTestProps(testIds.password)}
                      aria-label="Password"
                      fullWidth
                      name="password"
                      autoComplete="new-password"
                      component={FormikTextField}
                      placeholder={formatMessage(messages.password)}
                      validate={isPasswordSignUp && passwordValidator}
                      type="password"
                      startAdornment={<Lock aria-hidden />}
                    />
                  </div>
                </Fade>
              </Collapse>
              <Collapse
                in={isPasswordSignUp && showReenterPassword}
                className={classes.collapseContainer}
              >
                <Fade in={showReenterPassword}>
                  <div>
                    <Field
                      {...getTestProps(testIds.reenterPassword)}
                      aria-label="Re-entered Password"
                      fullWidth
                      name="reenteredPassword"
                      autoComplete="new-password"
                      component={FormikTextField}
                      type="password"
                      placeholder={formatMessage(messages.reenterPassword)}
                      validate={isPasswordSignUp && validators.required}
                      startAdornment={<Lock aria-hidden />}
                    />
                  </div>
                </Fade>
              </Collapse>
              <Collapse
                in={isPasswordSignUp}
                className={classes.collapseContainer}
              >
                <PasswordCriteria
                  className={classes.criteria}
                  passwordValue={getIn(formikProps.values, 'password')}
                />
              </Collapse>
              <Collapse
                in={!isPasswordSignUp}
                className={classes.collapseContainer}
              >
                <Text className={classes.signUpWithPassword}>
                  {formatMessage(messages.signUpWithPassword)}
                </Text>
              </Collapse>
              {signUpButton(formikProps.isSubmitting)}
            </FormBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SharedSignupForm;
