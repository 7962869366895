import { AsyncRequestContextState, AsyncRequestContext } from './reducer';
import { createSelector } from 'reselect';
import _isEqual from 'lodash/isEqual';
import qs from 'query-string';

// reference same empty array or obj to avoid shallow equality based re-renders
const emptyObj: any = {};
const emptyArr: any[] = [];

const defaultContextState = { activeRequests: {}, contexts: {} };

export const selectAsyncRequestContextsState = (
  state: any,
): AsyncRequestContextState =>
  state.asyncRequestContexts || defaultContextState;

export const selectContexts = (state: any) =>
  selectAsyncRequestContextsState(state).contexts || emptyObj;

export const selectActiveRequests = (state: any) =>
  selectAsyncRequestContextsState(state).activeRequests || emptyObj;

export const selectContextKeys = createSelector(
  selectContexts,
  asyncRequestContexts => Object.keys(asyncRequestContexts),
);
export const selectKeyedContext = (
  contexts: AsyncRequestContextState['contexts'] = emptyObj,
  contextKey: string,
): AsyncRequestContext => contexts[contextKey] || emptyObj;

export const selectContextRequest = (
  context: AsyncRequestContext = {},
): AsyncRequestContext['request'] => context.request || emptyObj;

export const selectRequestError = (
  request: AsyncRequestContext['request'] = {},
): any => request.error;

export const selectRequestErrorResponse = (
  request: AsyncRequestContext['request'] = {},
): any => request.errorResponse;

export const selectHasMadeRequest = (
  request: AsyncRequestContext['request'] = {},
): boolean => !_isEqual(request, emptyObj);

export const selectIsFetching = (
  request: AsyncRequestContext['request'] = {},
): boolean => !!request.isFetching;

export const selectNextToken = (context: AsyncRequestContext = {}): string =>
  // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
  context.nextToken;

export const selectContextResponses = (context: AsyncRequestContext): any[] =>
  context.responses || emptyArr;

export const selectHasNextToken = (nextToken: string): boolean => !!nextToken;

export const selectCount = (context: AsyncRequestContext = {}): number =>
  // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
  context.count;

export const bindContextKeyToSelectors = (contextKey: string) => {
  const contextActiveRequestSelector = createSelector(
    selectActiveRequests,
    activeRequests => activeRequests[contextKey],
  );

  const keyedContextSelector = createSelector(
    selectContexts,
    (contexts): AsyncRequestContext => selectKeyedContext(contexts, contextKey),
  );

  const requestSelector = createSelector(
    keyedContextSelector,
    (context): AsyncRequestContext['request'] => selectContextRequest(context),
  );

  const errorSelector = createSelector(requestSelector, request =>
    selectRequestError(request),
  );

  const errorResponseSelector = createSelector(requestSelector, request =>
    selectRequestErrorResponse(request),
  );

  const hasMadeRequestSelector = createSelector(requestSelector, request =>
    selectHasMadeRequest(request),
  );

  const isFetchingSelector = createSelector(requestSelector, request =>
    selectIsFetching(request),
  );

  const nextTokenSelector = createSelector(keyedContextSelector, context =>
    selectNextToken(context),
  );

  const countSelector = createSelector(keyedContextSelector, context =>
    selectCount(context),
  );

  const extractedNextTokenSelector = () =>
    createSelector(nextTokenSelector, nextToken =>
      nextToken ? qs.parse(qs.extract(nextToken)).next : null,
    );

  const responsesSelector = createSelector(keyedContextSelector, context =>
    selectContextResponses(context),
  );

  const hasNextTokenSelector = createSelector(nextTokenSelector, nextToken =>
    selectHasNextToken(nextToken),
  );

  return {
    contextActiveRequestSelector,
    countSelector,
    hasMadeRequestSelector,
    requestSelector,
    isFetchingSelector,
    keyedContextSelector,
    nextTokenSelector,
    extractedNextTokenSelector,
    responsesSelector,
    errorSelector,
    errorResponseSelector,
    hasNextTokenSelector,
  };
};
