import notificationsActionTypes from './actionTypes';
import actions, { NotificationOptions } from './actions';
import { createReducer } from '../../util/createReducer';

export interface Notification {
  message: string;
  options: NotificationOptions;
}

export interface NotificationState {
  notifications: Notification[];
}

const INITIAL_STATE: NotificationState = {
  notifications: [],
};

type AddNotifcationAction = ReturnType<typeof actions.addNotification>;

const reducer = createReducer(INITIAL_STATE, {
  [notificationsActionTypes.NOTIFICATIONS_QUEUE_NOTIFICATION]: (
    state,
    action: AddNotifcationAction,
  ) => {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        {
          message: action.message,
          options: action.options,
        },
      ],
    };
  },
  [notificationsActionTypes.NOTIFICATIONS_DEQUEUE_NOTIFICATION]: state => {
    return {
      ...state,
      notifications: state.notifications.slice(1),
    };
  },
  [notificationsActionTypes.NOTIFICATIONS_PURGE_QUEUE]: state => {
    return {
      ...state,
      notifications: [],
    };
  },
});

export default reducer;
