import deepFreeze from 'deep-freeze-strict';
import isEmpty from 'lodash/isEmpty';
import queryString, { StringifyOptions } from 'query-string';
import { window } from '@lifeomic/phc-web-toolkit/dist/util/globals';
import config from './config';
import { LoginApps } from './loginApps';
import { getStateParam } from './csrfToken';

const getOriginUri = () => {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '')
  );
};

export const appBaseSubdomains: Record<LoginApps, string> = {
  [LoginApps.phc]: 'apps',
  [LoginApps.lifeology]: 'app',
  [LoginApps.marketplace]: 'marketplace',
};

/**
 * redirectDomain was introduced to be able to test certain flows in localhost.
 * It is also only enabled for dev since it is a security concern for prod.
 * For certain apps (e.g. S2), this feature does more harm than good in the
 * social auth flow when running in dev. This list can be used if the app wishes
 * to ignore the localhost functionatility.
 */
export const LOGIN_APPS_IGNORING_SOCIAL_AUTH_REDIRECT_DOMAIN: LoginApps[] = [];

const urls = {
  api: {
    oauth: {
      token: () => '/api/v1/oauth/token',
    },
    auth: {
      cognito: {
        login: (
          subdomain: string,
          redirectDomain: string,
          destination: string,
          app: string,
          search = {}
        ) =>
          `/${config.authBasePath}/v1/login${stringifyParams({
            subdomain,
            redirectDomain,
            app,
            destination,
            state: getStateParam(),
            ...search,
          })}`,
        signup: (
          subdomain: string,
          redirectDomain: string,
          destination: string,
          app: string,
          search = {}
        ) =>
          `/${config.authBasePath}/v1/signup${stringifyParams({
            subdomain,
            redirectDomain,
            app,
            destination,
            state: getStateParam(),
            ...search,
          })}`,
        socialIdp: (
          identityProvider: 'Facebook' | 'Google' | 'SignInWithApple',
          subdomain: string,
          redirectDomain: string,
          destination: string,
          app: string,
          loginApp: LoginApps,
          search = {}
        ) =>
          `/${config.authBasePath}/v1/social-idp${stringifyParams({
            identity_provider: identityProvider, // eslint-disable-line @typescript-eslint/camelcase
            subdomain,
            redirectDomain:
              LOGIN_APPS_IGNORING_SOCIAL_AUTH_REDIRECT_DOMAIN.includes(loginApp)
                ? undefined
                : redirectDomain,
            destination,
            app,
            state: getStateParam(),
            ...search,
          })}`,
        logout: (
          idp?: 'facebook' | 'google' | 'signinwithapple',
          app?: string
        ) => {
          const baseUri = config.oauthBaseUri;
          const clientId = config.oauthClientId;

          // NOTE: Order matters! Params must exactly match `provision-cognito`
          const redirectUri = `${getOriginUri()}/${
            config.loginAppBasePath
          }${stringifyParams(
            {
              logout: 'success',
              idp,
              app,
            },
            { sort: false }
          )}`;
          return `${baseUri}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(
            redirectUri
          )}`;
        },
      },
      redirectUri: () => {
        const loginApp = config.loginApp ?? LoginApps.phc;
        const baseSubdomain = appBaseSubdomains[loginApp];

        return config.nodeEnv === 'production'
          ? baseEnvUrl(`/${config.authBasePath}/v1/app-redirect`, baseSubdomain)
          : config.localDevAuthRedirectUrl;
      },
      token: `/${config.authBasePath}/v1/api/token`,
      signup: `/${config.authBasePath}/v1/api/signup`,
      uniqueUsername: `/${config.authBasePath}/v1/api/unique-username`,
      confirm: `/${config.authBasePath}/v1/api/confirm`,
      resendCode: `/${config.authBasePath}/v1/api/resend`,
      login: `/${config.authBasePath}/v1/api/login`,
      forgotPassword: `/${config.authBasePath}/v1/api/forgot-password`,
      resetPassword: `/${config.authBasePath}/v1/api/reset-password`,
      getClientId: (subDomain: string) =>
        `/${config.authBasePath}/v1/api/client-id${stringifyParams({
          subDomain,
        })}`,
      verifyPasswordlessAuth: `/${config.authBasePath}/v1/api/passwordless-auth/verify`,
      passwordlessAuth: `/${config.authBasePath}/v1/api/passwordless-auth`,
    },
  },
  app: {
    roots: {
      app: (params?: any) => `/${stringifyParams(params)}`,
      verification: (params?: any) => `/verification${stringifyParams(params)}`,
      login: (params?: any) => `/signin${stringifyParams(params)}`,
      passwordlessLogin: (params?: any) =>
        `/email-login${stringifyParams(params)}`,
      forgotPassword: (params?: any) =>
        `/forgot-password${stringifyParams(params)}`,
      passwordlessAuthVerify: (params?: any) =>
        `/email-login/verify${stringifyParams(params)}`,
      signup: '/signup',
      pendingSocialLogout: (params?: any) =>
        `/pending-social-logout${stringifyParams(params)}`,
      pendingSharedLogoutAction: () => `/pending-shared-logout`,
      ssoRedirect: '/sso-redirect',
    },
  },
  external: {
    appleLogout: 'https://appleid.apple.com/',
    googleLogout: 'https://accounts.google.com/Logout',
    facebookLogout: 'https://facebook.com',
    privacy: 'https://lifeomic.com/privacy/',
    systemStatus: 'https://status.us.lifeomic.com/',
    termsConditions: 'https://lifeomic.com/terms-conditions/',
    lifeAppsTOU: 'https://lifeapps.io/life-mobile-apps-terms-of-use/',
    lifeologyCommunityLogin: 'https://lifeology.io/login',
    lifeologyPrivacyPolicy: 'https://lifeology.io/privacy',
    lifeologyTermsOfUse: 'https://lifeology.io/terms-of-use',
    privacyPolicyLink: 'https://lifeapps.io/life-mobile-apps-privacy-policy/',
  },
};

/**
 * Helpers
 */
function stringifyParams(
  params: Record<string, any>,
  options?: StringifyOptions
) {
  return isEmpty(params) ? '' : `?${queryString.stringify(params, options)}`;
}

export function baseEnvUrl(uri: string, baseSubdomain = 'apps') {
  const hostParts = window.location.host.split('.');
  const host =
    hostParts.length === 1
      ? hostParts.join('.')
      : hostParts[0] === baseSubdomain && hostParts[1] !== baseSubdomain
      ? hostParts.join('.')
      : hostParts.slice(1).join('.');
  return `${window.location.protocol}//${host}${
    uri.startsWith('/') ? uri : '/' + uri
  }`;
}

export function getHashParams(href: string) {
  if (!href) return null;
  const hashIndex = href.indexOf('#');
  return href
    .substring(hashIndex + 1)
    .split('&')
    .reduce((params, part) => {
      const [key, ...value] = part.split('=');
      (params as any)[key] = value.join('=');
      return params;
    }, {});
}

export default {
  ...deepFreeze(urls),
};
