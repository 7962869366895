import React from 'react';
import PhcContentContainer from './PhcContentContainer';
import { LoginApps } from '../../../util/loginApps';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { PaperProps } from '@lifeomic/chroma-react/components/Paper';
import SharedContentContainer from './SharedContentContainer';

export const appContentContainers: Record<
  LoginApps,
  React.FC<ContentContainerProps>
> = {
  [LoginApps.phc]: PhcContentContainer,
  [LoginApps.lifeology]: SharedContentContainer,
  [LoginApps.marketplace]: SharedContentContainer,
};

export interface ContentContainerOwnProps {
  isInitializing?: boolean;
}

export type ContentContainerProps = ContentContainerOwnProps & PaperProps;

const ContentContainer = (props: ContentContainerProps) => {
  const loginApp = useLoginApp();

  return appContentContainers[loginApp](props);
};

export default React.memo(ContentContainer);
