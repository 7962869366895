import { createSelector } from 'reselect';
import { AccountState } from './reducer';

export const selectAccountState = (state: any): AccountState => state.account;

export const selectAccounts = createSelector(selectAccountState, account => {
  const response: Partial<AccountState['response']> = account.response || {};
  return response.accounts || [];
});

export const selectActiveAccountId = (state: any) =>
  selectAccountState(state)?.activeAccount;

export const selectActiveAccount = createSelector(
  selectActiveAccountId,
  selectAccounts,
  (activeAccountId, accounts) =>
    activeAccountId && accounts.find((a: any) => a.id === activeAccountId),
);

export const selectIsFetching = (state: any) =>
  selectAccountState(state).isFetching;

export const selectError = (state: any) => selectAccountState(state).error;

export const selectLastFetchAttempt = (state: any) =>
  selectAccountState(state).lastFetchAttempt;
