import React from 'react';
import { LoginApps } from '../../../util/loginApps';
import SharedSignupForm from './SharedSignupForm';
import PhcSignupForm from './PhcSignupForm';
import useLoginApp from '../../../redux/hooks/useLoginApp';

export const appSignupForms: Record<LoginApps, React.FC<SignupFormProps>> = {
  [LoginApps.phc]: PhcSignupForm,
  [LoginApps.lifeology]: SharedSignupForm,
  [LoginApps.marketplace]: SharedSignupForm,
};

export type SignupFormProps = {};

const SignupForm = (props: SignupFormProps) => {
  const loginApp = useLoginApp();

  return appSignupForms[loginApp](props);
};

export default React.memo(SignupForm);
