import React from 'react';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';
import LifeologyBrandedBackground from './LifeologyBrandedBackground';
import PhcBrandedBackground from './PhcBrandedBackground';
import { MarketplaceBrandedBackground } from './MarketplaceBrandedBackground';

export type BrandedBackgroundOwnProps = {};

export type BrandedBackgroundProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  BrandedBackgroundOwnProps;

export const appBrandedBackgrounds: Record<
  LoginApps,
  React.FC<BrandedBackgroundProps>
> = {
  [LoginApps.lifeology]: LifeologyBrandedBackground,
  [LoginApps.phc]: PhcBrandedBackground,
  [LoginApps.marketplace]: MarketplaceBrandedBackground,
};

const BrandedBackground: React.FC<BrandedBackgroundProps> = (
  props: BrandedBackgroundProps
) => {
  const loginApp = useLoginApp();

  return appBrandedBackgrounds[loginApp](props);
};

export default React.memo(BrandedBackground);
