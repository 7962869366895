import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { Link } from '@mui/material';
import { Button } from '@lifeomic/chroma-react/components/Button';
import { push } from 'connected-react-router';
import urls from '../../util/urls';
import { getTestProps } from '../../util/testProps';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import { loginSelectors } from '../../redux/modules/Login';
import { CustomTheme, useCustomTheme } from '../../hooks/useCustomTheme';

const messages = {
  label: {
    id: 'forgotPasswordLink.label',
    defaultMessage: 'Forgot your password?',
  },
};

export const testIds = {
  link: 'forgotPasswordLink-link',
};

type StyleProps = {
  loginApp: string;
  customTheme?: CustomTheme;
};

export const useStyles = makeStyles((theme: Theme) => ({
  label: ({ loginApp, customTheme }: StyleProps) =>
    getLabelStyles(theme, loginApp, customTheme),
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5, 3),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  lifeologyTextBtn: {
    color: theme.palette.primary.contrastText,
  },
}));

const getLabelStyles = (
  theme: Theme,
  loginApp: string,
  customTheme?: CustomTheme
) => {
  switch (loginApp) {
    case LoginApps.lifeology:
      return {};
    case LoginApps.phc:
      return {
        color: customTheme?.primaryColor ?? theme.palette.primary.main,
        '&:hover': {
          color: customTheme?.primaryColor ?? theme.palette.primary.main,
        },
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: theme.pxToRem(0.5),
        padding: theme.spacing(1, 0),
      };
    default:
      return {
        color: customTheme?.primaryColor ?? theme.palette.blue[600],
        '&:hover': {
          color: customTheme?.primaryColor ?? theme.palette.blue[600],
        },
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.button.fontSize,
        letterSpacing: theme.pxToRem(0.5),
        padding: theme.spacing(1, 0),
      };
  }
};

const ForgotPasswordLink = () => {
  const formatMessage = useIntl().formatMessage;
  const loginApp = useLoginApp();
  const isLifeologyLogin = loginApp === LoginApps.lifeology;
  const customTheme = useCustomTheme();
  const classes = useStyles({ loginApp, customTheme });
  const dispatch = useDispatch();
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);

  const handleLinkClick = useCallback(() => {
    dispatch(
      push(
        urls.app.roots.forgotPassword({
          originalUrl,
          theme: customTheme ? JSON.stringify(customTheme) : undefined,
        })
      )
    );
  }, [originalUrl, customTheme]);

  return (
    <div className={clsx(classes.root)}>
      {isLifeologyLogin ? (
        <Button
          {...getTestProps(testIds.link)}
          className={classes.lifeologyTextBtn}
          onClick={handleLinkClick}
          variant="text"
        >
          {formatMessage(messages.label)}
        </Button>
      ) : (
        <Link
          {...getTestProps(testIds.link)}
          href="#"
          onClick={handleLinkClick}
          color="primary"
          className={classes.label}
        >
          {formatMessage(messages.label)}
        </Link>
      )}
    </div>
  );
};

export default React.memo(ForgotPasswordLink);
