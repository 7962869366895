import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Divider } from '@lifeomic/chroma-react/components/Divider';
import clsx from 'clsx';
import { theme } from '@lifeomic/phc-web-toolkit/dist/theme/phc';
import SignInButton from './SignInButton';
import { DotLoader } from '@lifeomic/chroma-react/components/DotLoader';
import SingupLinkButton from './SingupLinkButton';
import { SignInSSOButton } from './SignInSSOButton';
import { useSelector } from 'react-redux';
import { loginSelectors } from '../../redux/modules/Login';
import precisionOcrLogo from '../../static/images/precision-ocr-logo.svg';
import { getTestProps } from '../../util/testProps';
import { ButtonLink } from '@lifeomic/chroma-react/components/ButtonLink';
import generateReturnToLoginLink from '../../redux/util/generateReturnToLoginLink';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { Paper } from '@lifeomic/chroma-react/components/Paper';
import { SEA_GREEN } from '@lifeomic/phc-web-toolkit/dist/theme/phc/styles/content';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { FLGrayStacked } from '@lifeomic/phc-web-toolkit/dist/components/Logos';

const messages = {
  or: {
    id: 'MobileContentContainer.or',
    defaultMessage: 'or',
  },
  returnToLogin: {
    id: 'MobileContentContainer.returnToLogin',
    defaultMessage: 'Not wanting to use SSO?',
  },
};

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: theme.pxToRem(10),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: 'auto',
    maxWidth: '90%',
    width: theme.pxToRem(375),
  },
  controlContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  loadingRoot: {
    height: theme.pxToRem(250),
  },
  logo: {
    marginBottom: theme.spacing(2),
    maxHeight: theme.pxToRem(100),
    width: '100%',
  },
  precisionOCRLogo: {
    marginBottom: theme.spacing(2),
    width: theme.pxToRem(50),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  orBox: {
    margin: theme.spacing(2, 0, 3),
    width: theme.pxToRem(160),
    '& hr': {
      flex: 1,
      margin: 0,
    },
  },
  orSignUpText: {
    color: theme.palette.text.hint,
    padding: theme.spacing(0, 2),
  },
  platformDots: {
    width: theme.pxToRem(100),
    '& > circle': {
      '&:nth-child(1)': {
        fill: theme.palette.blue.main,
      },
      '&:nth-child(2)': {
        fill: '#3170a8',
      },
      '&:nth-child(3)': {
        fill: '#437b79',
      },
      '&:nth-child(4)': {
        fill: SEA_GREEN,
      },
    },
  },
}));

export type MobileContentContainerClasses = GetClasses<typeof useStyles>;

export interface MobileContentContainerOwnProps {
  isInitializing: boolean;
}

export type MobileContentContainerProps = MobileContentContainerOwnProps;

const MobileContentContainer = (props: MobileContentContainerProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const subdomain = useSelector(loginSelectors.selectSubDomain);
  const isPrecisionOcr = useSelector(
    loginSelectors.selectIsSourceAppPrecisionOCR
  );
  const disableSsoSwitching = useSelector(
    loginSelectors.selectDisableSsoSwitching
  );
  const clientDisablesLifeomic = useSelector(
    loginSelectors.selectCustomClientDisablesLifeomic
  );
  const clientDisablesCustom = useSelector(
    loginSelectors.selectCustomClientDisablesCustom
  );

  const { isInitializing } = props;

  const content = isInitializing ? (
    <DotLoader className={classes.platformDots} />
  ) : (
    <>
      {isPrecisionOcr ? (
        <img
          src={precisionOcrLogo}
          className={classes.precisionOCRLogo}
          {...getTestProps('precision-ocr-landing-icon')}
        />
      ) : (
        <FLGrayStacked
          className={classes.logo}
          {...getTestProps('phc-landing-icon')}
        />
      )}
      <div className={classes.controlContainer}>
        <div className={classes.buttonContainer}>
          {!clientDisablesLifeomic && <SignInButton sso={false} />}
          {!clientDisablesCustom && <SignInButton sso={true} />}
        </div>
        <div className={clsx(classes.buttonContainer, classes.marginTop)}>
          {!disableSsoSwitching && clientDisablesCustom && <SignInSSOButton />}
          {!disableSsoSwitching &&
            !clientDisablesCustom &&
            clientDisablesLifeomic && (
              <ButtonLink
                className={classes.marginTop}
                target="_self"
                to={generateReturnToLoginLink({ subdomain })}
                variant="text"
              >
                {formatMessage(messages.returnToLogin)}
              </ButtonLink>
            )}
        </div>
        <Box className={classes.orBox} align="center" fullWidth>
          <Divider />
          <Text className={classes.orSignUpText}>
            {formatMessage(messages.or)}
          </Text>
          <Divider />
        </Box>
        <div className={classes.buttonContainer}>
          <SingupLinkButton />
        </div>
      </div>
    </>
  );
  return (
    <Paper
      className={clsx(
        classes.root,
        props.isInitializing && classes.loadingRoot
      )}
    >
      {content}
    </Paper>
  );
};

export default React.memo(MobileContentContainer);
