import get from 'lodash/get';
import { createSelector } from 'reselect';
import queryString from 'query-string';
import { LoginState, SignupInfo, LoginParams } from './reducer';
import urls from '../../../util/urls';
import config from '../../../util/config';
import { LoginSearchParam } from './actions';
import { LoginApps } from '../../../util/loginApps';

const selectLoginState = (state: any): LoginState => state.login;

const selectLoginApp = (state: any): LoginApps => state.config.loginApp;

const emptyObj: any = {};

const selectCustomClientId = createSelector(
  selectLoginState,
  (loginState) => loginState.customClientId
);

const selectClientIdentityProviders = createSelector(
  selectLoginState,
  // default to allowing the normal providers (everything other than custom/SSO):
  (loginState) =>
    loginState.clientIdentityProviders || [
      'LIFEOMIC',
      'FACEBOOK',
      'GOOGLE',
      'SIGNINWITHAPPLE',
    ]
);

const selectCustomClientDisablesLifeomic = createSelector(
  selectClientIdentityProviders,
  (providers) => !providers?.includes('LIFEOMIC')
);

const selectCustomClientDisablesCustom = createSelector(
  selectClientIdentityProviders,
  (providers) => !providers?.includes('CUSTOM')
);

const selectCustomClientDisablesFacebook = createSelector(
  selectClientIdentityProviders,
  (providers) => !providers?.includes('FACEBOOK')
);

const selectCustomClientDisablesGoogle = createSelector(
  selectClientIdentityProviders,
  (providers) => !providers?.includes('GOOGLE')
);

const selectCustomClientDisablesApple = createSelector(
  selectClientIdentityProviders,
  (providers) => !providers?.includes('SIGNINWITHAPPLE')
);

const selectSignupInfoState = createSelector(
  selectLoginState,
  (loginState: LoginState) =>
    get<LoginState, 'signupInfo', SignupInfo>(
      loginState,
      'signupInfo',
      emptyObj
    )
);

const selectSignupEmail = createSelector(
  selectSignupInfoState,
  (signupInfo: SignupInfo) =>
    get<SignupInfo, 'email', string>(signupInfo, 'email', null)
);

const selectSignupPassword = createSelector(
  selectSignupInfoState,
  (signupInfo: SignupInfo) =>
    get<SignupInfo, 'password', string>(signupInfo, 'password', null)
);

const selectSignupUsername = createSelector(
  selectSignupInfoState,
  (signupInfo: SignupInfo) =>
    get<SignupInfo, 'username', string>(signupInfo, 'username', null)
);

const selectHasUserEnteredSignupInfo = createSelector(
  selectSignupEmail,
  selectSignupPassword,
  selectSignupUsername,
  (email, password, username) => !!email && !!password && !!username
);

const selectLoginParams = createSelector(
  selectLoginState,
  (loginState: LoginState) =>
    get<LoginState, 'loginParams', LoginParams>(
      loginState,
      'loginParams',
      emptyObj
    )
);

const selectRedirectDomain = createSelector(
  selectLoginParams,
  (loginParams: LoginParams) =>
    get<LoginParams, 'redirectDomain', string>(
      loginParams,
      'redirectDomain',
      null
    )
);

const selectSubDomain = createSelector(
  selectLoginParams,
  (loginParams: LoginParams) =>
    get<LoginParams, 'subDomain', string>(loginParams, 'subDomain', null)
);

const selectApp = createSelector(
  selectLoginParams,
  (loginParams: LoginParams) =>
    get<LoginParams, 'app', string>(loginParams, 'app', null)
);

const selectDestination = createSelector(
  selectLoginParams,
  (loginParams: LoginParams) =>
    get<LoginParams, 'destination', string>(loginParams, 'destination', null)
);

const selectSearch = createSelector(
  selectLoginParams,
  (loginParams: LoginParams): LoginSearchParam =>
    get<LoginParams, 'search', string>(
      loginParams,
      'search',
      emptyObj
    ) as LoginSearchParam
);

const selectLocationHash = createSelector(
  selectLoginParams,
  (loginParams: LoginParams) => loginParams?.locationHash || null
);

const selectOriginalUrl = createSelector(
  selectRedirectDomain,
  selectSubDomain,
  selectApp,
  selectDestination,
  selectSearch,
  selectLocationHash,
  (redirectDomain, subDomain, app, destination, search, locationHash) => {
    try {
      const url = new URL(config.appsBaseUri);
      if (redirectDomain) {
        // apps-auth expects a protocol with the domain, strip protocol for
        // redirect url though.
        url.host = redirectDomain.replace(/(^\w+:|^)\/\//, '');
      }

      if (subDomain && url.hostname.split('.')[0] !== subDomain) {
        url.hostname = `${subDomain}.${url.hostname}`;
      }

      url.pathname = (app ? `/${app}` : '') + (destination ? destination : '');

      url.search = queryString.stringify(search as any);

      if (locationHash) {
        url.hash = locationHash;
      }

      return url.href;
    } catch (e) {
      console.error(
        `Error occurred parsing url.  Redirect domain: ${redirectDomain}; Env app base uri: ${config.appsBaseUri}`,
        e
      );
      return '';
    }
  }
);

const selectIsSourceAppPrecisionOCR = createSelector(selectApp, (app) => {
  return app === 'precision-ocr';
});

const selectCognitoRedirectParams = createSelector(
  selectRedirectDomain,
  selectSubDomain,
  selectOriginalUrl,
  (redirectDomain, subDomain, originalUrl) => ({
    redirectDomain,
    subDomain,
    destination: null,
    app: config.loginAppBasePath, // always come back to login then go to the source app
    search: {
      'login-app-redirect-url': originalUrl,
    },
  })
);

const selectCognitoLoginUrl = createSelector(
  selectCognitoRedirectParams,
  ({ redirectDomain, subDomain, destination, app, search }) =>
    urls.api.auth.cognito.login(
      subDomain,
      redirectDomain,
      destination,
      app,
      search
    )
);

const selectCognitoSignupUrl = createSelector(
  selectCognitoRedirectParams,
  ({ redirectDomain, subDomain, destination, app, search }) =>
    urls.api.auth.cognito.signup(
      subDomain,
      redirectDomain,
      destination,
      app,
      search
    )
);

const selectAppleIdpUrl = createSelector(
  selectCognitoRedirectParams,
  selectLoginApp,
  ({ redirectDomain, subDomain, destination, app, search }, loginApp) =>
    urls.api.auth.cognito.socialIdp(
      'SignInWithApple',
      subDomain,
      redirectDomain,
      destination,
      app,
      loginApp,
      search
    )
);

const selectGoogleIdpUrl = createSelector(
  selectCognitoRedirectParams,
  selectLoginApp,
  ({ redirectDomain, subDomain, destination, app, search }, loginApp) =>
    urls.api.auth.cognito.socialIdp(
      'Google',
      subDomain,
      redirectDomain,
      destination,
      app,
      loginApp,
      search
    )
);

const selectFacebookIdpUrl = createSelector(
  selectCognitoRedirectParams,
  selectLoginApp,
  ({ redirectDomain, subDomain, destination, app, search }, loginApp) =>
    urls.api.auth.cognito.socialIdp(
      'Facebook',
      subDomain,
      redirectDomain,
      destination,
      app,
      loginApp,
      search
    )
);

const selectSocialLoginDisabled = createSelector(
  selectLoginParams,
  (loginParams) => loginParams.noSocial
);

const selectHidePasswordless = createSelector(
  selectLoginParams,
  (loginParams) => loginParams.hidePasswordless
);

const selectSimpleSignup = createSelector(
  selectLoginParams,
  (loginParams) => loginParams.simpleSignup
);

const selectDisableSsoSwitching = createSelector(
  selectLoginParams,
  (loginParams) => loginParams.disableSsoSwitching
);

const selectInviteId = createSelector(
  selectLoginParams,
  (loginParams) => loginParams.inviteId
);

const selectEVC = createSelector(
  selectLoginParams,
  (loginParams) => loginParams.evc
);

export default {
  selectSignupInfoState,
  selectSignupEmail,
  selectSignupPassword,
  selectSignupUsername,
  selectHasUserEnteredSignupInfo,
  selectRedirectDomain,
  selectSubDomain,
  selectApp,
  selectDestination,
  selectSearch,
  selectLocationHash,
  selectCognitoLoginUrl,
  selectOriginalUrl,
  selectCognitoSignupUrl,
  selectCustomClientId,
  selectAppleIdpUrl,
  selectGoogleIdpUrl,
  selectFacebookIdpUrl,
  selectSocialLoginDisabled,
  selectClientIdentityProviders,
  selectCustomClientDisablesLifeomic,
  selectCustomClientDisablesCustom,
  selectCustomClientDisablesFacebook,
  selectCustomClientDisablesGoogle,
  selectCustomClientDisablesApple,
  selectDisableSsoSwitching,
  selectIsSourceAppPrecisionOCR,
  selectInviteId,
  selectEVC,
  selectHidePasswordless,
  selectSimpleSignup,
};
