import { Box } from '@lifeomic/chroma-react/components/Box';
import { ButtonLink } from '@lifeomic/chroma-react/components/ButtonLink';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { FormBox } from '@lifeomic/chroma-react/components/FormBox';
import { getTestProps } from '../../util/testProps';
import { Link } from '@lifeomic/chroma-react/components/Link';
import { makeStyles } from '@lifeomic/chroma-react/styles';
import { Paper } from '@lifeomic/chroma-react/components/Paper';
import { FLGrayStacked } from '@lifeomic/phc-web-toolkit/dist/components/Logos';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { TextField } from '@lifeomic/chroma-react/components/TextField';
import * as React from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import urls from '../../util/urls';
import config from '../../util/config';
import { LoginApps } from '../../util/loginApps';
import useLoginApp from '../../redux/hooks/useLoginApp';
import lifeologyLogo from '../../static/images/lifeology-logo-horizontal.svg';
import useIsInGovCloudEnv from '../../hooks/useIsInGovCloudEnv';
import useIsDevTargetEnv from '../../hooks/useIsInDevEnv';
import { ologyColors } from '../../styles';

export const testIds: Record<string, string> = {
  textField: 'ssoRedirect-textField',
  signInButton: 'ssoRedirect-signInButton',
};

const messages = defineMessages({
  lifeologyLogo: {
    id: 'ssoRedirect.lifeologyLogo',
    defaultMessage: 'Lifeology Logo',
  },
  title: {
    id: 'ssoRedirect.title',
    defaultMessage: 'Sign in to your account',
  },
  textfieldLabel: {
    id: 'ssoRedirect.textfieldLabel',
    defaultMessage: 'Enter the subdomain of your account',
  },
  yourAccount: {
    id: 'ssoRedirect.yourAccount',
    defaultMessage: 'your-account',
  },
  textfieldPlaceholder: {
    id: 'ssoRedirect.textfieldPlaceholder',
    defaultMessage: 'subdomain',
  },
  signInButton: {
    id: 'ssoRedirect.signInButton',
    defaultMessage: 'Continue',
  },
  privacyPolicy: {
    id: 'ssoRedirect.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  termsConditions: {
    id: 'ssoRedirect.termsConditions',
    defaultMessage: 'Terms of Conditions',
  },
  systemStatus: {
    id: 'ssoRedirect.systemStatus',
    defaultMessage: 'Status',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.pxToRem(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    width: '21rem',
    position: 'relative',
  },
  logo: {
    flex: 0,
    marginBottom: theme.spacing(4),
    maxWidth: theme.pxToRem(105),
  },
  title: {
    textAlign: 'center',
  },
  textfield: {
    position: 'relative',
  },
  textFieldOverlay: {
    padding: theme.spacing(0.5, 1.5),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.625),
    fontSize: '1rem',
    height: '2.25rem',
    boxSizing: 'border-box',
    margin: `0 0 ${theme.spacing(2.5)}px`,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    color: 'transparent',
    pointerEvents: 'none',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightMedium,
    '&:before': {
      color: theme.palette.black.main,
      content: 'attr(data-overlay-prefix)',
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(0.375),
    },
    '&:after': {
      color: theme.palette.text.disabled,
      content: 'attr(data-overlay-suffix)',
      display: 'inline-block',
      background: 'transparent',
    },
  },
  textFieldOverlayEmpty: {
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    '&:before': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:after': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  textFieldInput: {
    '& input': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1rem',
      height: '2.25rem',
      marginRight: theme.spacing(0.25),
      maxWidth: '100%',
      padding: theme.spacing(1.5),
      width: '100%',
    },
  },
  fullWidth: {
    width: '100%',
  },
  // There's some weird bootstrap stuff overriding our styles,
  // so we need to override bootstrap...
  button: {
    '&:hover,&:focus': {
      backgroundColor: theme.palette.primary[800],
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&[aria-disabled=true]': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      opacity: 0.44,
    },
  },
  linksContainer: {
    marginTop: theme.spacing(2),
  },
  link: {
    fontSize: theme.pxToRem(12),
  },
  lifeologyLink: {
    fontSize: theme.pxToRem(12),
    color: ologyColors.link,
    '&:hover, &:focus': {
      color: ologyColors.linkHover,
    },
  },
}));

// https://usehooks.com/useLocalStorage
function useLocalStorage(key: string, initialValue: string) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: string | Function) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

const generateSSOURL = ({ subdomain }: { subdomain?: string }) => {
  switch (config.loginApp) {
    // Products that require vanity URLs for SSO: navigate to vanity URL
    default: {
      const searchParams = queryString.parse(window.location.search) as Record<
        string,
        string
      >;
      const vanityURLAppParams = {
        ...searchParams,
      };

      const prependSubDomain = (sourceUrl: string) => {
        if (sourceUrl.includes('https://www.')) {
          return sourceUrl.replace('https://www.', `https://www.${subdomain}.`);
        } else if (sourceUrl.includes('https://')) {
          return sourceUrl.replace('https://', `https://${subdomain}.`);
        } else {
          return sourceUrl;
        }
      };

      if (searchParams.originalUrl) {
        vanityURLAppParams.originalUrl = prependSubDomain(
          searchParams.originalUrl
        );
      }
      if (searchParams.redirectDomain) {
        vanityURLAppParams.redirectDomain = prependSubDomain(
          searchParams.redirectDomain
        );
      }

      const vanityURLAppSearch = `?${queryString.stringify(
        vanityURLAppParams
      )}`;
      return prependSubDomain(
        `https://${window.location.host}/login${vanityURLAppSearch}`
      );
    }
  }
};

export const generateDomainSuffix = (
  environment: string,
  isInGovCloudEnv: boolean,
  loginApp: LoginApps
) => {
  switch (loginApp) {
    case LoginApps.lifeology:
      return `.app.${environment}.${isInGovCloudEnv ? 'fed.' : ''}lifeology.io`;
    default:
      return `.apps.${environment}.${
        isInGovCloudEnv ? 'fed.' : ''
      }lifeomic.com`;
  }
};

interface HeaderProps {
  className: string;
  loginApp: LoginApps;
  formatMessage: (descriptor: MessageDescriptor) => string;
}

const Header: React.FC<HeaderProps> = ({
  className,
  loginApp,
  formatMessage,
}) => {
  switch (loginApp) {
    case LoginApps.lifeology:
      return (
        <img
          src={lifeologyLogo}
          alt={formatMessage(messages.lifeologyLogo)}
          className={className}
        />
      );
    default:
      return <FLGrayStacked className={className} role="img" aria-hidden />;
  }
};

export const SSORedirect: React.FC = () => {
  const classes = useStyles({});

  const { formatMessage } = useIntl();
  const loginApp = useLoginApp();
  const isLifeologyApp = loginApp === LoginApps.lifeology;

  const [localStorageSubDomain, setLocalStorageSubDomain] = useLocalStorage(
    '@@lifeomic/store/subdomain',
    ''
  );
  const [subdomain, setSubdomain] = React.useState<string>(
    localStorageSubDomain
  );

  // if this is PHC or marketplace we want to land back on the
  // login app (E.G. /login) with the code to do the authorization
  // code grant.  The query param `login-app-redirect-url` will
  // redirect us to our final destination.
  const environment = useIsDevTargetEnv() ? 'dev' : 'us';
  const isInGovCloudEnv = useIsInGovCloudEnv();
  const domainSuffix = generateDomainSuffix(
    environment,
    isInGovCloudEnv,
    loginApp
  );

  return (
    <Paper className={classes.root}>
      <Header
        className={classes.logo}
        loginApp={loginApp}
        formatMessage={(message) => formatMessage(message)}
      />

      <form
        className={classes.fullWidth}
        onSubmit={(e) => {
          e.preventDefault();

          if (!subdomain) {
            return;
          }

          if (subdomain !== localStorageSubDomain) {
            setLocalStorageSubDomain(subdomain);
          }

          window.location.assign(
            generateSSOURL({
              subdomain,
            })
          );
        }}
      >
        <FormBox fullWidth fullHeight>
          <Text className={classes.title} size="headline">
            {formatMessage(messages.title)}
          </Text>
          <div
            className={classes.textfield}
            aria-describedby="accountName subDomain"
            aria-label={
              domainSuffix
                ? `Text input with ${domainSuffix} appended to the text value.`
                : undefined
            }
          >
            <div
              className={clsx(
                classes.textFieldOverlay,
                !subdomain && classes.textFieldOverlayEmpty
              )}
              id="subDomain"
              data-overlay-suffix={domainSuffix}
              aria-hidden="true"
            >
              {subdomain || formatMessage(messages.yourAccount)}
            </div>
            <TextField
              {...getTestProps(testIds.textField)}
              aria-label="Enter your account name"
              className={classes.textFieldInput}
              id="accountName"
              type="text"
              value={subdomain}
              onChange={(e) => setSubdomain(e.target.value)}
              name="account"
              fullWidth
            />
          </div>
          <ButtonLink
            {...getTestProps(testIds.signInButton)}
            className={classes.button}
            target="_self"
            to={generateSSOURL({
              subdomain,
            })}
            disabled={!subdomain}
            onClick={() => setLocalStorageSubDomain(subdomain)}
          >
            {formatMessage(messages.signInButton)}
          </ButtonLink>
          <Box className={classes.linksContainer} justify="space-between">
            <Link
              className={isLifeologyApp ? classes.lifeologyLink : classes.link}
              to={
                isLifeologyApp
                  ? urls.external.lifeologyPrivacyPolicy
                  : urls.external.privacy
              }
              newTab
            >
              {formatMessage(messages.privacyPolicy)}
            </Link>
            <Link
              className={isLifeologyApp ? classes.lifeologyLink : classes.link}
              to={
                isLifeologyApp
                  ? urls.external.lifeologyTermsOfUse
                  : urls.external.termsConditions
              }
              newTab
            >
              {formatMessage(messages.termsConditions)}
            </Link>
            {!isLifeologyApp && (
              <Link
                className={classes.link}
                to={urls.external.systemStatus}
                newTab
              >
                {formatMessage(messages.systemStatus)}
              </Link>
            )}
          </Box>
        </FormBox>
      </form>
    </Paper>
  );
};
