import { Theme } from '@lifeomic/chroma-react/styles';

// ology = Lifeology
export const ologyColors = {
  btnGradient: 'linear-gradient(135deg, #00d2da 13.59%, #00b2f1 80.2%)',
  btnGradientHover: 'linear-gradient(135deg, #00d2da 25%, #00b2f1 100%)',
  link: '#008489',
  linkHover: '#00615e',
};

export const getTextFieldStyle = (theme: Theme) => {
  return {
    '& div > input': {
      // It's important that text inputs have at least a font size of 16px
      // for iOS to not zoom in on them when they are focused.
      fontSize: theme.typography.pxToRem(16),
    },
  };
};
