import _isNil from 'lodash/isNil';
import * as compositionUtils from './util';

export default class CompositionCacheHelper {
  private _compositions: fhir.Composition[];
  // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
  private _cachedReveresedCompositions: fhir.Composition[] = null;
  private _pageCompositionCache: fhir.Composition[] = [];

  constructor(compositions: fhir.Composition[]) {
    this._compositions = compositions.filter(Boolean).filter(_ => {
      if (_isNil(compositionUtils.pageIndexFromComposition(_))) {
        console.error(
          `Recieved composition without a page number.  This is required for all processing.  Composition id: ${_.id}`,
        );
        return false;
      }

      return true;
    });

    // sort up front
    this._compositions.sort(
      (c1, c2) =>
        // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
        compositionUtils.pageIndexFromComposition(c1) -
        // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
        compositionUtils.pageIndexFromComposition(c2),
    );
  }

  public getPageComposition(pageIndex: number) {
    if (!this._pageCompositionCache[pageIndex]) {
      // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
      this._pageCompositionCache[pageIndex] =
        this._compositions.find(
          _ => compositionUtils.pageIndexFromComposition(_) === pageIndex,
        ) || null;
    }

    return this._pageCompositionCache[pageIndex];
  }

  /**
   * Compositions sorted by page number in ascending order
   */
  public get compositions() {
    return this._compositions;
  }

  /**
   * Compositions sorted by page number in descending order
   */
  public get reversedCompositions() {
    if (!this._cachedReveresedCompositions) {
      const reveresedList = [...this._compositions];
      reveresedList.reverse();
      this._cachedReveresedCompositions = reveresedList;
    }
    return this._cachedReveresedCompositions;
  }

  public get maxPageNumber(): number | null {
    if (!this.reversedCompositions[0]) return null;

    return compositionUtils.pageIndexFromComposition(
      this.reversedCompositions[0],
    );
  }
}
