import React from 'react';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';
import LifeologySignInContainer from './LifeologySignInContainer';
import PhcSignInContainer from './PhcSignInContainer';
import { MarketplaceSignInContainer } from './MarketplaceSignInContainer';

export const appsSignInContainer: Record<
  LoginApps,
  React.FC<SignInContainerProps>
> = {
  [LoginApps.phc]: PhcSignInContainer,
  [LoginApps.lifeology]: LifeologySignInContainer,
  [LoginApps.marketplace]: MarketplaceSignInContainer,
};

export type SignInContainerOwnProps = {};

export type SignInContainerProps = SignInContainerOwnProps;

const SignInContainer = (props: SignInContainerProps) => {
  const loginApp = useLoginApp();

  return appsSignInContainer[loginApp](props);
};

export default React.memo(SignInContainer);
