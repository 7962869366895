import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Divider } from '@lifeomic/chroma-react/components/Divider';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { Link } from '@lifeomic/chroma-react/components/Link';
import { DotLoader } from '@lifeomic/chroma-react/components/DotLoader';
import { loginActions, loginSelectors } from '../../../../redux/modules/Login';
import { SignupContainerProps } from '../';
import {
  useSignupEndpoint,
  useUniqueUsernameEndpoint,
} from '../../../../hooks/api';
import CustomSSOSignup from '../../CustomSSOSignup';
import SignupForm from '../../SignupForm';
import SocialLogin from '../../SocialLogin';
import urls from '../../../../util/urls';
import useIsInGovCloudEnv from '../../../../hooks/useIsInGovCloudEnv';
import useIsMobile from '../../../../hooks/useIsMobile';
import { generatePassword } from '../../../../util/passwordlessAuth';
import { getTestProps } from '../../../../util/testProps';
import { LoginSearchParam } from '../../../../redux/modules/Login/actions';
import windowMethods from '../../../../util/windowMethods';
import { SEA_GREEN } from '@lifeomic/phc-web-toolkit/dist/theme/phc/styles/content';
import { AxiosResponse } from 'axios';
import handleAuthResponse from '../../../../util/handleAuthResponse';

export const testIds = {
  dotLoader: 'signupContainer-dotLoader',
};

const messages = {
  createAccountText: {
    id: 'signupContainer.createAccount',
    defaultMessage: 'Create Account',
  },
  orSignUp: {
    id: 'signupContainer.orSignUp',
    defaultMessage: 'or',
  },
  copyright: {
    id: 'signupContainer.copyrightText',
    defaultMessage: '{year} Fountain Life or its affiliates.',
  },
  allRightsReserved: {
    id: 'signupContainer.allRightsReserved',
    defaultMessage: 'All rights reserved.',
  },
  privacyPolicy: {
    id: 'signupContainer.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  termsConditions: {
    id: 'signupContainer.termsConditions',
    defaultMessage: 'Terms of Conditions',
  },
  systemStatus: {
    id: 'signupContainer.systemStatus',
    defaultMessage: 'Status',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    height: '100%',
    minHeight: theme.pxToRem(480),
    backgroundColor: theme.palette.common.white,
  },
  flexRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileRootVariant: {
    flex: 'unset',
    height: 'unset',
    width: '100%',
    minHeight: 'unset',
  },
  content: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: 'inherit',
  },
  createAccountText: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  orBox: {
    marginBottom: theme.spacing(2),
    '& hr': {
      flex: 1,
      margin: 0,
    },
  },
  orSignUpText: {
    color: theme.palette.text.hint,
    padding: theme.spacing(0, 2),
  },
  footer: {
    textAlign: 'center',
    listStyle: 'none',
    width: '100%',
    marginTop: theme.spacing(3),
    padding: '0',
    fontSize: theme.typography.caption.fontSize,
    position: 'absolute',
    left: 0,
    right: 0,
  },
  copyright: {
    color: theme.palette.text.secondary,
  },
  links: {
    '& a': {
      color: theme.palette.text.secondary,
    },
  },
  platformDots: {
    width: theme.pxToRem(100),
    '& > circle': {
      '&:nth-child(1)': {
        fill: theme.palette.blue.main,
      },
      '&:nth-child(2)': {
        fill: '#3170a8',
      },
      '&:nth-child(3)': {
        fill: '#437b79',
      },
      '&:nth-child(4)': {
        fill: SEA_GREEN,
      },
    },
  },
}));

export type SignupContainerClasses = GetClasses<typeof useStyles>;

type AutoSignUpState =
  | {
      email: string;
      social?: undefined;
    }
  | {
      social: 'facebook' | 'google';
      email?: undefined;
    };

const getAutoSignupState = (
  { email, social }: LoginSearchParam,
  options: {
    enableAutoSignup?: boolean;
    isSocialSignupEnabled: boolean;
    disableFacebook: boolean;
    disableGoogle: boolean;
  }
): AutoSignUpState => {
  if (!options.enableAutoSignup) {
    return null;
  }
  if (email && typeof email === 'string') {
    return { email };
  }
  if (social && typeof social === 'string' && options.isSocialSignupEnabled) {
    if (social === 'facebook' && !options.disableFacebook) {
      return {
        social: 'facebook',
      };
    }
    if (social === 'google' && !options.disableGoogle) {
      return {
        social: 'google',
      };
    }
  }

  return null;
};

export const PhcSignupContainer = (props: SignupContainerProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const isInGovCloudEnv = useIsInGovCloudEnv();
  const socialLoginDisabled = useSelector(
    loginSelectors.selectSocialLoginDisabled
  );
  const clientDisablesFacebook = useSelector(
    loginSelectors.selectCustomClientDisablesFacebook
  );
  const clientDisablesGoogle = useSelector(
    loginSelectors.selectCustomClientDisablesGoogle
  );
  const clientDisablesLifeomic = useSelector(
    loginSelectors.selectCustomClientDisablesLifeomic
  );
  const clientDisablesCustom = useSelector(
    loginSelectors.selectCustomClientDisablesCustom
  );
  const simpleSignup = useSelector(loginSelectors.selectSimpleSignup);
  const customClientPreventsSocial =
    clientDisablesFacebook && clientDisablesGoogle;
  const app = useSelector(loginSelectors.selectApp);
  const destination = useSelector(loginSelectors.selectDestination);
  const inviteId = useSelector(loginSelectors.selectInviteId);
  const evc = useSelector(loginSelectors.selectEVC);
  const [, signupUser] = useSignupEndpoint();
  const getUniqueUsername = useUniqueUsernameEndpoint();
  const dispatch = useDispatch();
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);

  const facebookLink = useSelector(loginSelectors.selectFacebookIdpUrl);
  const googleLink = useSelector(loginSelectors.selectGoogleIdpUrl);
  const search = useSelector(loginSelectors.selectSearch);

  const isSocialSignupEnabled =
    !isInGovCloudEnv && !customClientPreventsSocial && !socialLoginDisabled;

  const autoSignupState: AutoSignUpState | null = getAutoSignupState(search, {
    isSocialSignupEnabled,
    enableAutoSignup: props.enableAutoSignUp,
    disableFacebook: clientDisablesFacebook,
    disableGoogle: clientDisablesGoogle,
  });

  /**
   * When we load the signup container with an email in the query params, we
   * assume that the caller wants to trigger an automatic passwordless signup.
   */
  React.useEffect(() => {
    const autoSignUp = async () => {
      if (autoSignupState && !simpleSignup) {
        if (autoSignupState.email) {
          const username = await getUniqueUsername(autoSignupState.email);
          const password = generatePassword();

          const preRequestTime = new Date();
          signupUser(
            username,
            password,
            autoSignupState.email,
            originalUrl,
            (res: AxiosResponse) => {
              if (res?.data?.userConfirmed && res?.data?.accessToken) {
                const redirectUrl = res.data.originalUrl || originalUrl;
                return handleAuthResponse(
                  res.data,
                  preRequestTime,
                  autoSignupState.email,
                  redirectUrl
                );
              }
              dispatch(
                loginActions.persistSignupParams(
                  username,
                  autoSignupState.email,
                  password
                )
              );
              dispatch(
                push(
                  urls.app.roots.verification({
                    originalUrl,
                    email: autoSignupState.email,
                    username,
                  })
                )
              );
            },
            undefined, // first name
            undefined, // last name
            undefined, // recaptchaToken
            inviteId,
            evc,
            app,
            destination
          );
        } else if (autoSignupState.social === 'facebook') {
          windowMethods.setLocationHref(facebookLink);
        } else {
          windowMethods.setLocationHref(googleLink);
        }
      }
    };

    autoSignUp();
  }, [search.email, search.social]);

  return (
    <div
      className={clsx(classes.root, {
        // Center dot loader.
        [classes.flexRoot]: !!autoSignupState && !simpleSignup,
        // Keep the container large when we show the auto signup dot loader.
        [classes.mobileRootVariant]: isMobile && !autoSignupState,
      })}
    >
      {autoSignupState && !simpleSignup ? (
        <DotLoader
          {...getTestProps(testIds.dotLoader)}
          dotStyle={classes.platformDots}
        />
      ) : (
        <>
          <div className={classes.content}>
            <Text className={classes.createAccountText} size="headline">
              {formatMessage(messages.createAccountText)}
            </Text>
            {isSocialSignupEnabled && !simpleSignup && (
              <>
                <SocialLogin
                  disableApple
                  disableFacebook={clientDisablesFacebook}
                  disableGoogle={clientDisablesGoogle}
                />
                <Box className={classes.orBox} align="center" fullWidth>
                  <Divider />
                  <Text className={classes.orSignUpText}>
                    {formatMessage(messages.orSignUp)}
                  </Text>
                  <Divider />
                </Box>
              </>
            )}
            {!clientDisablesLifeomic && <SignupForm />}
            {clientDisablesLifeomic && !clientDisablesCustom && (
              <CustomSSOSignup />
            )}
          </div>
          <Box className={classes.footer} direction="column">
            <Text className={classes.copyright} size="caption">
              &copy;{' '}
              {formatMessage(messages.copyright, {
                year: new Date().getFullYear(),
              })}
              &nbsp;{formatMessage(messages.allRightsReserved)}
            </Text>
            <Box justify="center" className={classes.links}>
              <Link to={urls.external.privacy} newTab>
                {formatMessage(messages.privacyPolicy)}
              </Link>
              &nbsp;|&nbsp;
              <Link to={urls.external.termsConditions} newTab>
                {formatMessage(messages.termsConditions)}
              </Link>
              &nbsp;|&nbsp;
              <Link to={urls.external.systemStatus} newTab>
                {formatMessage(messages.systemStatus)}
              </Link>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};
