import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { FLGrayStacked } from '@lifeomic/phc-web-toolkit/dist/components/Logos';
import useIsMobile from '../../../hooks/useIsMobile';
import clsx from 'clsx';
import SignInButton from '../SignInButton';
import SharedDeviceToggle from '../../common/SharedDeviceToggle';
import { SignInSSOButton } from '../SignInSSOButton';
import { ButtonLink } from '@lifeomic/chroma-react/components/ButtonLink';
import { useSelector } from 'react-redux';
import { loginSelectors } from '../../../redux/modules/Login';
import precisionOcrLogo from '../../../static/images/precision-ocr-logo.svg';
import precisionOcrLogoStacked from '../../../static/images/precision-ocr-logo-stacked.svg';
import { SignInContainerProps } from '.';
import { getTestProps } from '../../../util/testProps';
import generateReturnToLoginLink from '../../../redux/util/generateReturnToLoginLink';
import { Text } from '@lifeomic/chroma-react/components/Text';

const messages = defineMessages({
  welcomeBack: {
    id: 'signInContainer.welcomeBack',
    defaultMessage: 'Welcome Back!',
  },
  returnToLogin: {
    id: 'signInContainer.returnToLogin',
    defaultMessage: 'Not wanting to use SSO?',
  },
});

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentRoot: {
    flex: 1,
    minHeight: theme.pxToRem(370),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileRootVariant: {
    flex: 'unset',
    height: 'unset',
    width: '100%',
    minHeight: 'unset',
    padding: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  logo: {
    marginBottom: theme.spacing(4),
    maxHeight: theme.pxToRem(198),
    width: theme.pxToRem(150),
  },
  mobileLogoVariant: {
    marginBottom: theme.spacing(2),
    height: theme.pxToRem(100),
  },
  welcomeBackText: {
    color: theme.palette.text.hint,
  },
  toggleContainer: {
    width: '100%',
    height: theme.pxToRem(45),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

export type SignInContainerlasses = GetClasses<typeof useStyles>;

const PhcSignInContainer = (props: SignInContainerProps) => {
  const { formatMessage } = useIntl();
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const subdomain = useSelector(loginSelectors.selectSubDomain);
  const isPrecisionOcr = useSelector(
    loginSelectors.selectIsSourceAppPrecisionOCR
  );
  const disableSsoSwitching = useSelector(
    loginSelectors.selectDisableSsoSwitching
  );
  const clientDisablesLifeomic = useSelector(
    loginSelectors.selectCustomClientDisablesLifeomic
  );
  const clientDisablesCustom = useSelector(
    loginSelectors.selectCustomClientDisablesCustom
  );

  const logoClassName = clsx(
    classes.logo,
    isMobile && classes.mobileLogoVariant
  );
  let logo: React.ReactElement = null;
  if (isPrecisionOcr) {
    logo = (
      <img
        src={isMobile ? precisionOcrLogo : precisionOcrLogoStacked}
        className={logoClassName}
        {...getTestProps('precision-ocr-landing-icon')}
      />
    );
  } else {
    const LogoComponent = FLGrayStacked;
    logo = (
      <LogoComponent
        className={clsx(classes.logo, isMobile && classes.mobileLogoVariant)}
        {...getTestProps('phc-landing-icon')}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.contentRoot,
          isMobile && classes.mobileRootVariant
        )}
      >
        {logo}
        <Text className={classes.welcomeBackText} size="headline">
          {formatMessage(messages.welcomeBack)}
        </Text>
        {!clientDisablesLifeomic && <SignInButton sso={false} />}
        {!clientDisablesCustom && <SignInButton sso={true} />}
        {!disableSsoSwitching && clientDisablesCustom && (
          <SignInSSOButton className={classes.marginTop} />
        )}
        {!disableSsoSwitching &&
          !clientDisablesCustom &&
          clientDisablesLifeomic && (
            <ButtonLink
              className={classes.marginTop}
              target="_self"
              to={generateReturnToLoginLink({ subdomain })}
              variant="text"
            >
              {formatMessage(messages.returnToLogin)}
            </ButtonLink>
          )}
      </div>
      {!isMobile && (
        <div className={classes.toggleContainer}>
          <SharedDeviceToggle />
        </div>
      )}
    </div>
  );
};

export default PhcSignInContainer;
