import React from 'react';
import { useIntl } from 'react-intl';
import {
  Formik,
  Form,
  Field,
  FormikProps,
  FormikHelpers,
  FormikErrors,
} from 'formik';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { FormikTextField } from '@lifeomic/phc-web-toolkit/dist/components/ChromaFormik';
import useValidators from '../../hooks/useValidatiors';
import { Button } from '@lifeomic/chroma-react/components/Button';
import { useForgotPasswordEndpoint } from '../../hooks/api';
import { AxiosResponse } from 'axios';
import { ForgotPasswordResponse } from '../../hooks/api/useForgotPasswordEndpoint';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import { getTestProps } from '../../util/testProps';
import { getTextFieldStyle, ologyColors } from '../../styles';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import { User } from '@lifeomic/chromicons';
import clsx from 'clsx';
import { CustomTheme, useCustomTheme } from '../../hooks/useCustomTheme';

const messages = {
  username: {
    id: 'forgotPasswordForm.username',
    defaultMessage: 'Username or email',
  },
  sendResetCode: {
    id: 'forgotPasswordForm.sendResetCode',
    defaultMessage: 'Send reset code',
  },
  sendSuccess: {
    id: 'forgotPasswordForm.sendSuccess',
    defaultMessage:
      'A reset password email will be sent to you if "{username}" is a recognized email or username',
  },
};

export const testIds = {
  username: 'forgotPasswordForm-username',
  submit: 'forgotPasswordForm-submitButton',
};

export type FormValues = {
  username: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  forgotPasswordButton: {
    marginTop: theme.spacing(4),
    height: 40,
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
  customThemeForgotPasswordButton: ({
    customTheme,
  }: SharedForgotPasswordFormProps) => ({
    background: customTheme?.primaryColor,
    color: customTheme?.textOnPrimaryColor,
    '&:disabled': {
      background: customTheme?.disabledColor,
    },
  }),
  lifeologyButton: {
    borderRadius: theme.pxToRem(20),
    backgroundColor: 'none',
    backgroundImage: ologyColors.btnGradient,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundImage: ologyColors.btnGradientHover,
    },
    '&:disabled': {
      backgroundImage: ologyColors.btnGradient,
      opacity: 0.65,
    },
  },
  criteria: {
    marginTop: theme.spacing(1),
  },
  textField: getTextFieldStyle(theme),
}));

export type ForgotPasswordFormClasses = GetClasses<typeof useStyles>;

export interface ForgotPasswordFormOwnProps {
  onSendSuccess: (username: string) => void;
}

export type ForgotPasswordFormProps = ForgotPasswordFormOwnProps;

export type SharedForgotPasswordFormProps = ForgotPasswordFormProps & {
  customTheme?: CustomTheme;
};

const initialValues: FormValues = {
  username: '',
};

const getInitialErrors = (
  validator: (value: string) => string
): FormikErrors<FormValues> => {
  return {
    username: validator(initialValues.username),
  };
};

const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const customTheme = useCustomTheme();
  const formatMessage = useIntl().formatMessage;
  const customProps = {
    ...props,
    customTheme,
  };
  const classes = useStyles(customProps);
  const validators = useValidators();
  const dispatch = useDispatch();
  const [sendResetCodeRequestState, sendResetCode] =
    useForgotPasswordEndpoint();
  const loginApp = useLoginApp();
  const isLifeologyLogin = loginApp === LoginApps.lifeology;
  const onSubmit = React.useCallback(
    (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      const { username } = formValues;

      sendResetCode(
        username,
        (_axiosResponse: AxiosResponse<ForgotPasswordResponse>) => {
          props.onSendSuccess(username);
          dispatch(
            notificationsActions.addNotification(
              formatMessage(messages.sendSuccess, { username }),
              { statusType: 'success' }
            )
          );
        }
      );

      actions.setSubmitting(false);
    },
    []
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialErrors={getInitialErrors(validators.required)}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Field
            {...getTestProps(testIds.username)}
            aria-label="Username"
            fullWidth
            name="username"
            autoComplete="username"
            component={FormikTextField}
            placeholder={formatMessage(messages.username)}
            validate={validators.required}
            startAdornment={<User aria-hidden />}
            className={classes.textField}
          />
          <Button
            {...getTestProps(testIds.submit)}
            className={clsx(
              classes.forgotPasswordButton,
              customTheme && classes.customThemeForgotPasswordButton,
              isLifeologyLogin && classes.lifeologyButton
            )}
            disabled={
              sendResetCodeRequestState.isLoading ||
              formikProps.isSubmitting ||
              !formikProps.isValid
            }
            type="submit"
            variant="contained"
          >
            {formatMessage(messages.sendResetCode)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(ForgotPasswordForm);
