import loginActionTypes from './actionTypes';
import { ClientIdentityProviders } from '../../../util/initializeApp';

export interface SignupAction {
  type: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
}

function persistSignupParams(
  username: string,
  email: string,
  password: string,
  firstName?: string,
  lastName?: string
): SignupAction {
  return {
    type: loginActionTypes.SIGNUP,
    firstName,
    lastName,
    username,
    email,
    password,
  };
}

type SearchParam = string | string[];
type ExpectedSearchParams = { email?: SearchParam };
type AnySearchParams = { [key: string]: SearchParam };
export type LoginSearchParam = AnySearchParams & ExpectedSearchParams;

export interface SetLoginParamsAction {
  type: string;
  redirectDomain: string;
  subDomain: string;
  app: string;
  destination: string;
  search: LoginSearchParam;
  noSocial: boolean;
  hidePasswordless: boolean;
  simpleSignup: boolean;
  locationHash?: string;
  disableSsoSwitching: boolean;
  inviteId?: string;
  evc?: string;
}

function setLoginParams(
  redirectDomain: string,
  subDomain: string,
  app: string,
  destination: string,
  search: LoginSearchParam,
  noSocial: boolean,
  hidePasswordless: boolean,
  simpleSignup: boolean,
  disableSsoSwitching: boolean,
  locationHash?: string,
  inviteId?: string,
  evc?: string
): SetLoginParamsAction {
  return {
    type: loginActionTypes.SET_LOGIN_PARAMS,
    redirectDomain,
    subDomain,
    app,
    destination,
    search,
    noSocial,
    locationHash,
    disableSsoSwitching,
    hidePasswordless,
    simpleSignup,
    inviteId,
    evc,
  };
}

export interface SetSubDomainClientIdAction {
  type: string;
  clientId: string;
}

function setSubDomainClientId(clientId: string): SetSubDomainClientIdAction {
  return {
    type: loginActionTypes.SET_CLIENT_ID,
    clientId,
  };
}

export interface SetClientIdentityProvidersAction {
  type: string;
  identityProviders: ClientIdentityProviders[];
}

function setClientIdentityProviders(
  identityProviders: ClientIdentityProviders[]
): SetClientIdentityProvidersAction {
  return {
    type: loginActionTypes.SET_CLIENT_IDENTITY_PROVIDERS,
    identityProviders,
  };
}

export default {
  persistSignupParams,
  setLoginParams,
  setSubDomainClientId,
  setClientIdentityProviders,
};
