import blue from '@lifeomic/chroma-react/colors/blue';
import grey from '@lifeomic/chroma-react/colors/grey';

const CONTAINER_MAX_WIDTH = 1100;

// To name colors, visit: https://chir.ag/projects/name-that-color
// Type in hexidecimal code and adopt it's approximate name.
const PELOROUS = '#509bc5';
const PUERTO_RICO = '#4cd4af';
const SEA_GREEN = '#307f71';

const contentRoot = {
  root: {
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: CONTAINER_MAX_WIDTH,
  },
};

const link = {
  color: blue.main,
  '&:hover': {
    color: blue.main,
  },
  '&:active': {
    color: blue.main,
  },
  '&:visited': {
    color: blue.main,
  },
};

const LOpaper = {
  padding: 30,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.13)',
};

const headCell = {
  fontSize: '0.875rem',
  fontWeight: 700,
  textTransform: 'none',
  position: 'sticky',
  '&::after': {
    content: "''",
    position: 'absolute',
    left: 0,
    width: '100%',
    bottom: 0,
    borderBottom: `4px solid ${blue.main}`,
  },
};

const stickyHeadCell = {
  ...headCell,
  background: grey[50],
  position: 'sticky',
  top: 0,
  zIndex: 1,
};

const subHeader = {
  marginTop: '16px',
};

const sideMenu = {
  openedWidth: 240,
  closedWidth: 40,
};

const breakPoints = {
  xs: 480,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};
const containers = breakPoints;

const icon = {
  width: 20,
  height: 20,
};

const primaryGradient270Deg = {
  backgroundImage: `linear-gradient(270deg, ${SEA_GREEN} 16.82%, ${blue.main} 84.62%)`,
};

const primaryGradient349Deg = {
  backgroundImage: `linear-gradient(349deg, ${SEA_GREEN} 16.82%, ${blue.main} 84.62%)`,
};

const secondaryGradient90Deg = {
  backgroundImage: `linear-gradient(90deg, ${PELOROUS} -72.67%, ${PUERTO_RICO} 100%)`,
};

export {
  contentRoot,
  LOpaper,
  headCell,
  link,
  subHeader,
  containers,
  breakPoints,
  icon,
  sideMenu,
  stickyHeadCell,
  primaryGradient270Deg,
  primaryGradient349Deg,
  secondaryGradient90Deg,
  PELOROUS,
  PUERTO_RICO,
  SEA_GREEN,
};
