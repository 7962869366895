import userPatientsActionTypes from './actionTypes';
import { createReducer } from '../../util/createReducer';

export interface UserPatientsState {
  isFetching: boolean;
  error: any;
  response?: any;
  lastFetchAttempt: Date;
  /**
   * Consuming apps may explicitely set user patient instead of fetch from the API
   */
  explicitelyAssignedUserPatient: fhir.Patient;
}

const INITIAL_STATE: UserPatientsState = {
  isFetching: false,
  error: null,
  response: null,
  // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
  lastFetchAttempt: null,
  // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
  explicitelyAssignedUserPatient: null,
};

const reducer = createReducer(INITIAL_STATE, {
  [userPatientsActionTypes.FETCH_USER_PATIENTS_START]: state => {
    return {
      ...state,
      isFetching: true,
      error: null,
    };
  },
  [userPatientsActionTypes.FETCH_USER_PATIENTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: false,
      response: action.response,
      lastFetchAttempt: new Date(),
    };
  },
  [userPatientsActionTypes.FETCH_USER_PATIENTS_ERROR]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: action.error,
      response: null,
      lastFetchAttempt: new Date(),
    };
  },
  [userPatientsActionTypes.EXPLICITELY_ASSIGN_USER_PATIENT]: (
    state,
    action,
  ) => {
    return {
      ...state,
      explicitelyAssignedUserPatient: action.patient,
    };
  },
});

export default reducer;
