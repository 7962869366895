import _get from 'lodash/get';
import { ProjectPickerState } from './reducer';
import { createSelector } from 'reselect';
import { MrnConfig, MrnConfigType } from '../../../common/types/Projects';
import { extensions } from '../../util/constants';

export const selectProjectPickerState = (state: any): ProjectPickerState =>
  state.projectPicker;

export const selectProjectCohortPickerState = (state: any): any =>
  state.projectCohortPicker;

export const selectActiveProjectId = (state: any) =>
  selectProjectPickerState(state)?.activeProject;

export const selectActiveProjectCohortId = (state: any) =>
  selectProjectCohortPickerState(state).activeProjectCohort;

export const selectProjectList = (state: any) =>
  selectProjectPickerState(state).projectList;

export const selectActiveProject = createSelector(
  selectActiveProjectId,
  selectProjectList,
  (activeProjectId, projectList) => {
    return projectList.find(p => p.id === activeProjectId);
  },
);

export const selectIsFetching = (state: any) =>
  selectProjectPickerState(state).isFetching;

export const selectError = (state: any) =>
  selectProjectPickerState(state).error;

export const selectLastFetchAttempt = (state: any) =>
  selectProjectPickerState(state).lastFetchAttempt;

export const selectIsActiveProjectPart11 = createSelector(
  selectActiveProject,
  activeProject => !!_get(activeProject, 'part11', false),
);

const DEFAULT_MRN_CONFIG: MrnConfig = {
  configType: MrnConfigType.typeCoding,
  system: extensions.patient.hl7.fhir.mr,
  code: 'MR',
};

export const selectActiveMrnConfig = createSelector(
  selectActiveProject,
  activeProject => _get(activeProject, 'mrnConfig', DEFAULT_MRN_CONFIG),
);
