import _get from 'lodash/get';

export type SocialIDP = 'facebook' | 'google' | 'signinwithapple';

export interface User {
  id: string;
  mfaSettings: {
    preferredMethod: string;
    settingList: any[];
  };
  profile: {
    email: string;
    emailVerified: boolean;
    familyName: string;
    givenName: string;
    name: string;
    picture: string;
  };
  type: 'lifeomic' | SocialIDP;
  userCreateDate: string;
}

export const selectUserState = (state: any) => state.user;

export const selectActiveUser = (state: any): User =>
  selectUserState(state).response;

export const selectActiveUserId = (state: any) => {
  const user = selectActiveUser(state);
  return user ? user.id : null;
};

export const selectUserSocialIDP = (state: any): SocialIDP => {
  const user = selectActiveUser(state);
  const type = _get<User, 'type', string>(user, 'type', '');

  if (type === 'google') {
    return 'google';
  } else if (type === 'facebook') {
    return 'facebook';
  } else if (type === 'signinwithapple') {
    return 'signinwithapple';
  } else {
    // @ts-expect-error This directive was added as part of a bulk-change to enable strictNullChecks.
    return null;
  }
};

export const selectIsFetching = (state: any) =>
  selectUserState(state).isFetching;

export const selectError = (state: any) => selectUserState(state).error;

export const selectLastFetchAttempt = (state: any) =>
  selectUserState(state).lastFetchAttempt;
