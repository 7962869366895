import loginActionTypes from './actionTypes';
import createReducer from '../../util/createReducer';
import {
  SignupAction,
  SetLoginParamsAction,
  SetSubDomainClientIdAction,
  SetClientIdentityProvidersAction,
} from './actions';
import { ClientIdentityProviders } from '../../../util/initializeApp';

export interface SignupInfo {
  username: string;
  email: string;
  password: string;
}

export type LoginSearchParam = { [key: string]: string | string[] };

export interface LoginParams {
  redirectDomain: string;
  subDomain: string;
  app: string;
  destination: string;
  search: LoginSearchParam;
  noSocial: boolean;
  locationHash?: string;
  disableSsoSwitching: boolean;
  inviteId?: string;
  evc?: string;
  hidePasswordless: boolean;
  simpleSignup: boolean;
}

export interface LoginState {
  signupInfo: SignupInfo;
  loginParams: LoginParams;
  customClientId: string;
  clientIdentityProviders: ClientIdentityProviders[] | null;
}

const INITIAL_STATE: LoginState = {
  signupInfo: {
    username: null,
    email: null,
    password: null,
  },
  loginParams: {
    redirectDomain: null,
    subDomain: null,
    app: null,
    destination: null,
    search: {},
    noSocial: false,
    disableSsoSwitching: false,
    hidePasswordless: false,
    simpleSignup: false,
  },
  customClientId: null,
  clientIdentityProviders: null,
};

const loginReducer = createReducer(INITIAL_STATE, {
  [loginActionTypes.SIGNUP]: (
    state: LoginState,
    action: SignupAction
  ): LoginState => ({
    ...state,
    signupInfo: {
      ...state.signupInfo,
      username: action.username,
      email: action.email,
      password: action.password,
    },
  }),
  [loginActionTypes.SET_LOGIN_PARAMS]: (
    state: LoginState,
    action: SetLoginParamsAction
  ): LoginState => ({
    ...state,
    loginParams: {
      ...state.loginParams,
      redirectDomain: action.redirectDomain,
      subDomain: action.subDomain,
      app: action.app,
      destination: action.destination,
      search: action.search || {},
      noSocial: action.noSocial,
      hidePasswordless: action.hidePasswordless,
      locationHash: action.locationHash,
      disableSsoSwitching: action.disableSsoSwitching,
      inviteId: action.inviteId,
      evc: action.evc,
      simpleSignup: action.simpleSignup,
    },
  }),
  [loginActionTypes.SET_CLIENT_ID]: (
    state: LoginState,
    action: SetSubDomainClientIdAction
  ): LoginState => ({
    ...state,
    customClientId: action.clientId,
  }),
  [loginActionTypes.SET_CLIENT_IDENTITY_PROVIDERS]: (
    state: LoginState,
    action: SetClientIdentityProvidersAction
  ): LoginState => ({
    ...state,
    clientIdentityProviders: action.identityProviders,
  }),
});

export default loginReducer;
